import LocomotiveScroll from "locomotive-scroll";
//import "locomotive-scroll.css";
// new LocomotiveScroll({
// 	el: document.querySelector("#site"),
// 	smooth: true,
// 	smartphone: {
// 		smooth: true,
// 	},
// });
let batteryLevel;

window.addEventListener("DOMContentLoaded", (event) => {
	const loader = document.querySelector("#loader");

	setTimeout(() => {
		loader.remove();
	}, 100);

	console.log("DOM fully loaded and parsed");
});

const scroll = new LocomotiveScroll({
	el: document.querySelector("[data-scroll-container]"),
	smooth: true,
	//touchMultiplier: 2,
	mobile: {
		smooth: true,
		breakpoint: 0,
		multiplier: 1.5,
	},
	smartphone: {
		smooth: true,
		breakpoint: 767,
		multiplier: 1.5,
	},
	tablet: {
		smooth: true,
		breakpoint: 1024,
	},
});

navigator.getBattery().then((battery) => {
	console.log(battery);
	document.querySelector("#battery").innerHTML = battery?.level;
	if (battery?.level <= 0.1) {
		batteryLevel = "bawa";
		scroll.destroy();
	}
});

console.log(batteryLevel);
// touchMultiplier: 2,
// 	smoothMobile: 0,
// 	smartphone: {
// 		smooth: !0,
// 		breakpoint: 767,
// 	},
// 	tablet: {
// 		smooth: !1,
// 		breakpoint: 1024,
// 	},
